import React, { forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { useMutation } from '@apollo/react-hooks';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { DELETE_REMOTE_PACS, GET_REMOTE_PACS_LIST } from '../../../../../queries/RemotePacs/RemotePacs';
import { networkErrorParse } from '../../../../../utils/ErrorGraphQLUtils';
import { ExtraBold } from '../../../../Common/styled/Text';
import { GreyLabel } from '../../../../Common/styled/GreyLabel';
import Loading from '../../../../Common/Loading';
import { AlertUI } from '../../../../../componentsUI/Alert';
import {InnerSpanHTML} from "../../../../Common/InnerHTML";

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '100%',
      maxWidth: 600,
      padding: 15,
      '@media (max-width:600px)': {
        margin: 5,
        padding: 10,
        '& > div': {
          paddingLeft: 5,
          paddingRight: 5,
        },
      },
    },
  },
  group: {
    padding: 8,
    '& .type': {
      marginBottom: 4,
    },
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
}));

export const DeleteRemotePacsDialog = ({ open, onClose, remotePacs = {} }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const hospital = useSelector((state) => state.hospital);
  const { uuid: remotePacsUuid } = remotePacs;
  const [serverError, setServerError] = useState(false);

  const [deleteRemotePacs, { loading, called }] = useMutation(DELETE_REMOTE_PACS, {
    onCompleted() {
      onClose({ status: 'deleted' });
      toast(t('remote.pacs.removed'), { className: 'toast-info' });
    },
    onError(error) {
      const errorMsg = networkErrorParse(error) || 'server.error';
      setServerError(errorMsg);
    },
    refetchQueries: [{
      query: GET_REMOTE_PACS_LIST,
      variables: {
        hospitalUuid: hospital.uuid,
      },
      fetchPolicy: 'cache-and-network',
    }],
    awaitRefetchQueries: true,
  });

  const handleClose = () => {
    setServerError(false);
    onClose({ status: 'close' });
  };

  const handleDeletePacs = () => {
    setServerError(false);
    deleteRemotePacs({ variables: { remotePacsUuid } }).then();
  };

  const serverName = remotePacs && remotePacs.serverName;

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle>{t('remote.pacs.confirm.delete')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <InnerSpanHTML
            content={t('remote.pacs.delete.confirmation.message', { name: serverName })}
          />
        </DialogContentText>

        {remotePacs && remotePacs.dicomNodes && remotePacs.dicomNodes.map((node, index) => (
          <Grid container spacing={0} key={index.toString()} className={classes.group}>
            <Grid item xs={12} className="type">
              <GreyLabel>{node.type === 1 ? 'C-MOVE' : 'C-FIND'}</GreyLabel>
            </Grid>
            <Grid item xs={12}>
              <GreyLabel>{`${t('ae.title')}: `}</GreyLabel>
              <ExtraBold>{node.aeTitle}</ExtraBold>
            </Grid>
            <Grid item xs={12}>
              <GreyLabel>{`${t('ip')}: `}</GreyLabel>
              <ExtraBold>{node.ip}</ExtraBold>
            </Grid>
            <Grid item xs={12}>
              <GreyLabel>{`${t('port')}: `}</GreyLabel>
              <ExtraBold>{node.port}</ExtraBold>
            </Grid>
          </Grid>
        ))}
        {called && loading && <Loading />}
        {serverError && <AlertUI severity="error" title="Error">{t(serverError)}</AlertUI>}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          className={classes.button}
          color="primary"
          onClick={onClose}
          disabled={loading}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          onClick={handleDeletePacs}
          disabled={loading}
        >
          {t('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteRemotePacsDialog;
