import React from 'react';

import { Layout } from '../../../../components/MenuLayout';
import { RemotePacsList } from '../../../../components/Hospital/Management/RemotePacs/RemotePacsList';

export default () => (
  <Layout type="settings">
    <RemotePacsList />
  </Layout>
);
