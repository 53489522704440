import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Container } from '@material-ui/core';

import { navigate } from 'gatsby';
import { AddBoxOutlined, Ballot, DeleteTwoTone, Edit, SettingsApplications } from '@material-ui/icons';
import { useQuery } from '@apollo/react-hooks';
import { makeStyles } from '@material-ui/core/styles';
import { Navbar } from '../../../Navbar/styled/NavbarStyles';
import { SectionBar } from '../../../../componentsUI/SectionBar';
import { PageContent } from '../../../Common/styled/PageContent';
import Can, { cannotI } from '../../../../casl/Can';
import { AlertUI, AlertWrapperUI } from '../../../../componentsUI/Alert';
import { isEmpty } from '../../../../utils/ObjectUtils';
import Loading from '../../../Common/Loading';
import { CardWrapperUI } from '../../../Common/styled/CardWrapperUI';
import TableCollapse from '../../../../componentsUI/TableCollapse';
import { GET_REMOTE_PACS_LIST } from '../../../../queries/RemotePacs/RemotePacs';
import { DeleteRemotePacsDialog } from './modal/DeleteRemotePacsDialog';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '1.5rem',
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  subtitle: {
    fontSize: '1.25rem',
    fontWeight: 600,
    color: theme.palette.grey.A200,
    marginTop: -2,
    '& > .MuiBox-root': {
      display: 'inline',
    },
    '& .ae-title': {
      color: theme.palette.grey.A700,
      marginLeft: 6,
    },
    '& .address': {
      color: theme.palette.primary.light,
      marginLeft: 12,
    },
  },
}));

export const RemotePacsList = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const hospital = useSelector((state) => state.hospital);
  const subtitle = t('hospital.settings.external.dicom.nodes.configuration');

  const { loading, data } = useQuery(
    GET_REMOTE_PACS_LIST, {
      variables: {
        hospitalUuid: hospital.uuid,
      },
      fetchPolicy: 'cache-and-network',
    },
  );
  const remotePacsList = data && data.remotePacsList;
  const selectedRemotePacs = selected.length && selected.length === 1 && remotePacsList[selected[0]];

  const handleOpenAdd = () => navigate('/hospital/settings/remote-pacs/new');
  const handleOpenView = () => navigate(`/hospital/settings/remote-pacs/view/${selectedRemotePacs.uuid}`);
  const handleOpenEdit = () => navigate(`/hospital/settings/remote-pacs/edit/${selectedRemotePacs.uuid}`);

  const handleRowViewDetail = (evt, index) => {
    evt.stopPropagation();
    const indexAccess = remotePacsList[index];

    navigate(`/hospital/settings/remote-pacs/view/${indexAccess.uuid}`);
  };

  const handleOpenDelete = () => setShowDeleteModal(true);
  const handleCloseDelete = ({ status }) => {
    if (status && status === 'deleted') setSelected([]);
    setShowDeleteModal(false);
  };

  const getTitle = (item) => (
    <Box className={classes.title}>
      <SettingsApplications />
      {item.serverName}
    </Box>
  );

  const getSubTitle = (item) => {
    const dicomNode = item && item.dicomNodes && item.dicomNodes.length && item.dicomNodes[0];
    if (!dicomNode) return '';

    return (
      <Box className={classes.subtitle}>
        <Box>
          AE Title:
        </Box>
        <Box className="ae-title">
          {dicomNode.aeTitle}
        </Box>
        <Box className="address">
          {`${dicomNode.ip}:${dicomNode.port}`}
        </Box>
      </Box>
    );
  };

  const tableRowButtons = [{ Icon: Ballot, tooltip: 'view.details' }];
  const headerButtons = [
    { name: 'add.pacs', icon: AddBoxOutlined, I: 'delete', data: hospital, handleClick: handleOpenAdd },
    { name: 'divider2', type: 'divider' },
    { name: 'edit.pacs.settings', icon: Edit, I: 'delete', data: hospital, handleClick: handleOpenEdit, disabled: selected.length !== 1 },
    { name: 'view.details', icon: Ballot, I: 'delete', data: hospital, handleClick: handleOpenView, disabled: selected.length !== 1 },
    { name: 'divider2', type: 'divider' },
    { name: 'remove.pacs', icon: DeleteTwoTone, I: 'delete', data: hospital, handleClick: handleOpenDelete, disabled: selected.length === 0 },
  ];

  return (
    <>
      <Navbar>
        <SectionBar
          title="hospital.settings.remote.pacs"
          subtitle={subtitle}
          items={headerButtons}
        />
      </Navbar>
      <DeleteRemotePacsDialog
        open={showDeleteModal}
        onClose={handleCloseDelete}
        remotePacs={selectedRemotePacs}
      />
      <Container maxWidth="lg">
        <PageContent>
          {!hospital && <Loading />}
          {hospital && cannotI('manage', hospital) && (
            <AlertWrapperUI>
              <AlertUI severity="warning" title={t('non.authorized')}>
                {t('you.are.not.allowed.to.change.these.settings')}
              </AlertUI>
            </AlertWrapperUI>
          )}
          {hospital && hospital.menuOptionRemotePacs !== true ? (
            <AlertWrapperUI>
              <AlertUI severity="warning" title={t('not.available')}>
                {t('this.configuration.is.not.enabled')}
              </AlertUI>
            </AlertWrapperUI>
          ) : (
            <Can I="manage" this={hospital}>
              {loading && isEmpty(data) ? <Loading /> : (
                <CardWrapperUI>
                  {(remotePacsList && remotePacsList.length) ? (
                    <TableCollapse
                      responsive={false}
                      fieldNames={[]}
                      items={remotePacsList}
                      GetTitle={getTitle}
                      GetSubtitle={getSubTitle}
                      handleGoto={handleRowViewDetail}
                      ActionButtons={tableRowButtons}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  ) : (
                    <AlertWrapperUI>
                      <AlertUI severity="info" title="Info">
                        {t('no.remote.pacs.configured')}
                      </AlertUI>
                    </AlertWrapperUI>
                  )}
                </CardWrapperUI>
              )}
            </Can>
          )}
        </PageContent>
      </Container>
    </>
  );
};

export default RemotePacsList;
